<template>
  <Auth
    title="Reset Password?"
    description="Please verify your account information and select how you'd like to receive the reset link"
    header-logo="forget-password"
  >
    <div v-if="maskedPhone && isSMSConfigured" class="flex gap-1 mb-1 font-inter">
      <UiCheckBox
        label="Sms to"
        type="radio"
        name="reset_option"
        value="sms"
        :no-error="true"
        lable-direction="flex gap-1"
        @change="updateStatus"
      />
      <div class="font-inter font-medium text-primary-gray-600 text-base ml-1">
        {{ `${maskedPhone}` }}
      </div>
    </div>
    <div class="flex gap-3 mb-2">
      <UiCheckBox
        label="Email to"
        type="radio"
        name="reset_option"
        value="email"
        checked
        lable-direction="flex gap-1"
        :no-error="true"
        @change="updateStatus"
      />
      <span class="font-medium text-primary-gray-600 text-base mt-1">
        {{ `${maskedEmail}` }}
      </span>
    </div>

    <UIButton button="primary" :disabled="isLoading" class="w-full mt-1" @click="sendOTPHandler">
      <span v-if="isLoading">
        <Loader />
      </span>
      <span v-else>Continue</span>
    </UIButton>
  </Auth>
</template>

<script>
import Auth from '@layouts/auth.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import UiCheckBox from '@components/UiElements/UiCheckbox.vue'
import { mapActions } from 'vuex'
import { COMMUNICATION_MEDIUMS } from '@src/constants/general-constants'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  components: {
    Auth,
    UiCheckBox,
    Loader,
    UIButton,
  },
  page: {
    title: 'Options',
    meta: [
      {
        name: 'description',
        content: 'Choose Options ',
      },
    ],
  },
  data() {
    return {
      medium: '',
      isLoading: false,
      AllowedMediums: [COMMUNICATION_MEDIUMS.EMAIL, COMMUNICATION_MEDIUMS.SMS],
    }
  },
  computed: {
    userEmail() {
      return this.$route.query.email
    },
    maskedEmail() {
      return this.$route.query.maskedEmail
    },
    maskedPhone() {
      return this.$route.query.maskedPhone
    },
    userDomain() {
      return this.$route.query.subDomain
    },
    isSMSConfigured() {
      return this.$route.query.isSMSConfigured
    },
  },
  methods: {
    updateStatus(medium) {
      this.medium = medium.value
    },
    sendOTPHandler() {
      if (!this.medium) {
        this.$store.commit('toast/NEW', {
          message: 'Please choose an option to reset password',
          type: 'error',
          duration: 6000,
        })
        return
      }
      let isMediumAllowed = this.AllowedMediums.includes(this.medium)
      if (!isMediumAllowed) return
      this.isLoading = true
      let payload = { identifier: this.userEmail, sub_domain: this.userDomain, medium: this.medium }
      this.forgotPassword(payload)
        .then(() => {
          this.isLoading = false
          this.$router?.push({ name: 'login' })
          this.$store.commit('toast/NEW', {
            message: 'Reset password link sent to provided account. Please check',
            type: 'info',
            duration: 6000,
          })
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    ...mapActions('auth', ['forgotPassword']),
  },
}
</script>

<style lang="scss" module>
@import '@design';
</style>
